import React, { useState } from "react";

import { AppLayout, CustomerCard } from "@shared/salesTool";
import { ParentWrapper } from "@shared/salesTool/components.styles";
import { Container, GridContainer, Input } from "@util/standard";
import { useCustomersFromFirestore } from "@util/firebaseHooks";
import { assets } from "@util/constants";
import { useStore } from "@state/store";
import { useSearchOrders } from "@util/hooks";
import { Customer } from "@state/types";
import { Button } from "@global";
import { isBrowser } from "@util/helper";

export default function Customers() {
  const { user } = useStore();

  if (!isBrowser()) return null;

  const [customers, loading] = useCustomersFromFirestore();
  const [searchTerm, setSearchTerm] = useState("");

  const [searchResults, clearSearchResults] = useSearchOrders(customers, searchTerm);
  const customersToDisplay = searchTerm.length ? searchResults : customers;

  const handleClearFilters = () => {
    setSearchTerm("");
    clearSearchResults();
  };

  return (
    <AppLayout currentPath="customers">
      <ParentWrapper>
        <Container width="100%" justifyContent="space-between" alignItems="center">
          <h2>Customers</h2>
          <Container width="30%">
            {Boolean(searchTerm) && (
              <Button
                theme="text"
                margin="0 20px 0 0"
                linkText="Clear"
                onClick={handleClearFilters}
              />
            )}

            <Input
              margin="0 0 0 20px"
              width="120%"
              name="search"
              placeholder="Search"
              onChange={e => setSearchTerm(e.target.value)}
              value={searchTerm}
            />
          </Container>
        </Container>
        {user && loading && (
          <Container width="50%" margin="auto" justifyContent="center">
            <img src={assets.loadingIcon} />
          </Container>
        )}

        {user && customers && customers.length && (
          <GridContainer repeat={1} tabletRepeat={1} rowGap={20}>
            {customersToDisplay?.map(customer => {
              if (customer == null) return null;
              return <CustomerCard key={customer.id} data={customer as Customer} />;
            })}
          </GridContainer>
        )}
      </ParentWrapper>
    </AppLayout>
  );
}
